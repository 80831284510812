<template>
  <v-layout row wrap>
    <v-flex class="pa-1" xs12>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :search="search"
        :items="contacts"
        :pagination.sync="pagination"
        select-all
        :loading="contactsIsLoading ? 'accent' : false"
        item-key="title"
        class="elevation-3"
        :hide-actions="contacts <= 5"
      >
        <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable text-xs-left',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
              ]"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <v-icon small>arrow_upward</v-icon>
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr>
            <td>
              <v-switch
                v-model="props.item.enabled"
                @change="updateEnabledStatus(props.item)"
                v-if="checkScope(scopeLiterals.ContactsUpdate)"
              ></v-switch>
            </td>
            <td width="20%">{{ props.item.name }}</td>
            <td>{{ props.item.id }}</td>
            <td class="text-xs-left" width="160px">
              <img v-if="props.item.source === 'Xero'" src="xero-small.png" style="height: 30px" />
              <img
                v-if="props.item.source === 'QuickBooks'"
                src="qb-logo-horizontal-preferred.svg"
                style="height: 40px"
              />
              <img v-if="props.item.source === 'NetSuite'" src="netsuite.png" style="height: 20px" />
              <div v-if="props.item.source === 'Manual'" class="tw-w-48 tw-h-14 tw-mt-2">
                <v-select
                  :items="validAccountingConnections"
                  item-value="id"
                  :value="props.item.accountingConnectionId"
                  @input="($e) => sourceChanged($e, props.item)"
                  solo
                >
                  <template v-slot:selection="{ item }">
                    <img class="tw-h-6 tw-mr-3" :src="getProviderIcon(item.provider)" />{{ getConnectionName(item) }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img class="tw-h-6 tw-mr-3" :src="getProviderIcon(item.provider)" />{{ getConnectionName(item) }}
                  </template>
                </v-select>
              </div>
              <img v-if="props.item.source === 'SageIntacct'" src="sageintacct.png" style="height: 20px" />
            </td>
            <td class="vendor-addresses" width="185px" style="max-width: 185px">
              <template v-if="props.item.addresses">
                <ui-tooltip
                  v-for="addressRecord in props.item.addresses.slice(0, 3)"
                  :key="addressRecord.coin + addressRecord.address"
                  :text="addressRecord.address"
                  position="top"
                  class="tw-align-middle tw-ml-2"
                >
                  <div class="tw-truncate">
                    <span class="coin">{{ addressRecord.coin }}</span
                    >&nbsp;
                    <span class="address">{{ addressRecord.address }}</span>
                  </div>
                </ui-tooltip>
                <div v-if="props.item.addresses.length > 3" class="tw-text-gray-500">
                  + {{ props.item.addresses.length - 3 }} more
                </div>
              </template>
            </td>
            <td width="150px" style="max-width: 150px">
              <ui-tooltip
                v-for="(value, key) in props.item.metadata"
                :key="key + props.item.id"
                :text="`${key}:${value}`"
                position="top"
                class="tw-align-middle tw-ml-2"
              >
                <div class="tw-truncate">{{ key }} : {{ value }}</div>
              </ui-tooltip>
            </td>
            <td width="20%">
              <div v-if="props.item.defaultRevenueCategoryId">
                <b>{{ $tc('_revenueCategory') }}:</b> {{ getCategoryNameById(props.item.defaultRevenueCategoryId) }}
              </div>
              <div v-if="props.item.defaultExpenseCategoryId">
                <b>{{ $tc('_expenseCategory') }}:</b> {{ getCategoryNameById(props.item.defaultExpenseCategoryId) }}
              </div>
            </td>
            <td class="text-xs-right">
              <contact-addresses
                @default-category-update="updateDefaultCategory"
                :contact="props.item"
                v-on:addressChanged="refresh"
                v-if="checkScope(scopeLiterals.ContactsUpdate)"
              />
            </td>
          </tr>
        </template>
        <template slot="footer">
          <td :colspan="headers.length">
            <v-text-field
              class="search-box"
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </td>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';
import _ from 'lodash';

import { ConnectionCategory, ConnectionStatus, Providers } from '@/api-svc-types';
import { getAccountingProviderIcon } from '@/utils/accountingProviders';

import UiTooltip from '../ui/UiTooltip.vue';
import ContactAddresses from './ContactAddresses';

export default {
  props: [],
  components: {
    ContactAddresses,
    UiTooltip,
  },
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            isDisabled
            isDeleted
            category
            name
            feeAccountCode
            isDefault
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      update(data) {
        return (
          data.connections?.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection) ?? []
        );
      },
    },
  },
  data() {
    return {
      search: undefined,
      isLoading: false,
      connections: [],
      pagination: {
        sortBy: 'name',
      },
      selected: [],
      headers: [
        {
          text: this.$t('_enabled'),
          value: 'enabled',
        },
        {
          text: this.$t('_name'),
          align: 'left',
          value: 'name',
        },
        {
          text: 'Bitwave ID',
          align: 'left',
          value: 'id',
        },
        {
          text: this.$t('_source'),
          align: 'left',
          value: 'source',
        },
        {
          text: this.$tc('_address', 2),
          align: 'left',
          value: 'addresses',
        },
        { text: 'Metadata', align: 'left', value: 'metadata' },
        { text: this.$tc('_defaultCategory', 2) },
        {
          text: '',
          align: 'right',
          value: 'action',
        },
      ],
    };
  },
  created() {
    this.refresh();
  },
  computed: {
    contacts() {
      const disabledConnectionIds = this.connections
        .filter((c) => {
          return c.category === ConnectionCategory.AccountingConnection && c.isDisabled;
        })
        .map((c) => {
          return c.id;
        });
      const contacts = this.$store.getters['contacts/CONTACTS'];
      return contacts.filter((c) => {
        return disabledConnectionIds.indexOf(c.accountingConnectionId) === -1;
      });
    },
    contactsIsLoading() {
      return this.$store.getters['contacts/CONTACTS_ISLOADING'];
    },
    validAccountingConnections() {
      const accountingConnections = this.connections.filter((c) => {
        return c.category === ConnectionCategory.AccountingConnection && !c.isDisabled;
      });
      const manualAccountingConnection = {
        id: 'Manual',
        provider: Providers.Manual,
        status: ConnectionStatus.Ok,
      };
      return accountingConnections.concat(manualAccountingConnection);
    },
  },
  methods: {
    cleanMeta(addresses) {
      return _.omit(addresses, ['__typename']);
    },
    addressesToArray(addresses) {
      return Object.keys(this.cleanMeta(addresses))
        .map((coin) => ({ coin, address: addresses[coin] }))
        .filter((x) => x.address);
    },
    refresh() {
      this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id);
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    getCategoryNameById(searchId) {
      return (
        this.$store.getters['categories/ENABLE_CATEGORIES'].find((category) => category.id === searchId)?.name ??
        searchId
      );
    },

    updateDefaultCategory(contact, revenue, categoryId) {
      const variables = {
        orgId: this.$store.state.currentOrg.id,
        contact: { id: contact.id, enabled: contact.enabled },
      };
      if (revenue === true) {
        if (categoryId) variables.contact.defaultRevenueCategoryId = categoryId;
        variables.contact.defaultExpenseCategoryId = contact.defaultExpenseCategoryId;
      } else {
        if (categoryId) variables.contact.defaultExpenseCategoryId = categoryId;
        variables.contact.defaultRevenueCategoryId = contact.defaultRevenueCategoryId;
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($orgId: ID!, $contact: UpdateContactInput!) {
              updateContact(orgId: $orgId, contact: $contact) {
                name
                enabled
                defaultExpenseCategoryId
                defaultRevenueCategoryId
              }
            }
          `,
          // Parameters
          variables,
        })
        .then((res) => {
          console.log(res);
          this.refresh();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateEnabledStatus(item) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        contact: {
          id: item.id,
          enabled: item.enabled,
        },
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($orgId: ID!, $contact: UpdateContactInput!) {
              updateContact(orgId: $orgId, contact: $contact) {
                name
                enabled
                defaultExpenseCategoryId
                defaultRevenueCategoryId
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then((res) => {
          // snackbar
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProviderIcon(provider) {
      return getAccountingProviderIcon(provider);
    },
    getConnectionName(conn) {
      let name = conn.name;
      if (conn.provider === 'Manual') {
        name = 'Bitwave';
      }

      if (!name) {
        name = conn.provider;
      }

      return name;
    },
    sourceChanged(e, item) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        contact: {
          id: item.id,
          connectionId: e,
        },
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($orgId: ID!, $contact: UpdateContactInput!) {
              updateContact(orgId: $orgId, contact: $contact) {
                name
                enabled
                defaultExpenseCategoryId
                defaultRevenueCategoryId
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then((res) => {
          // snackbar
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.vendor-addresses p {
  margin: 0;
  font-size: 0.8em;
}
.vendor-addresses span.coin {
  font-weight: bold;
}
.vendor-addresses span.address {
  font-family: monospace;
}
</style>
